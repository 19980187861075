<template>
  <main>
    <router-view v-if="productsPerCategory" />

    <MissingMessage
      v-else
      :main-message="$t('OFFER.OFFER_IS_MISSING')"
      :secondary-message="$t('OFFER.GO_BACK_TO_GENERATE')"
      class="margins__triple"
    />
  </main>
</template>

<script>
import { mapState } from 'vuex';
import MissingMessage from '@/components/offer/general/MissingMessage';

export default {
  name: 'Offer',
  components: {
    MissingMessage,
  },
  computed: {
    ...mapState('offerStore', ['productsPerCategory']),
  },
};
</script>
