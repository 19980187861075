<template>
  <Margins class="text-center">
    <h4>
      {{ mainMessage }}
      <br />
      {{ secondaryMessage }}
    </h4>

    <Button to="/">{{ $t('OFFER.BACK_TO_THE_CALCULATOR') }}</Button>
  </Margins>
</template>

<script>
import Button from '@/components/Button';
import Margins from '@/components/Margins';

export default {
  name: 'MissingMessage',
  components: { Button, Margins },
  props: {
    mainMessage: {
      type: String,
      required: true,
    },
    secondaryMessage: {
      type: String,
    },
  },
};
</script>
